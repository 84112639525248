import React from 'react';
import { ToastProvider } from 'react-toast-notifications';
import { toast } from 'react-toastify'
import { BrowserRouter as Router } from "react-router-dom";

import Whatsapp from './components/WhatsappFloatingIcon'
import AppRouter from './navigators/AppRouter';

import 'react-toastify/dist/ReactToastify.css'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

toast.configure({
  autoClose: 3000,
})
function App() {
  return (
    <div className="App">
      <Whatsapp />
      <ToastProvider>
        <Router>
          <AppRouter />
        </Router>
      </ToastProvider>
    </div>
  );
}

export default App;
