import { min } from 'moment';

export const getWebinarTime = (timestamp) => {
  let hours = new Date(`${timestamp}`).getUTCHours();
  let minutes = new Date(`${timestamp}`).getUTCMinutes();
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  let amPm = 'AM';
  if (hours > 12) {
    hours = hours - 12;
    amPm = 'PM';
  }

  return `${hours}:${minutes} ${amPm}`;
};

export const getWebinarDate = (timestamp) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  let month = new Date(timestamp).getUTCMonth();
  let date = new Date(timestamp).getUTCDate();
  let year = new Date(timestamp).getUTCFullYear();

  return `${months[month]} ${date}, ${year}`;
};

export function deleteCookie() {
  const cookies = document.cookie.split('; ');
  for (let c = 0; c < cookies.length; c++) {
    const d = window.location.hostname.split('.');
    while (d.length > 0) {
      const cookieBase =
        encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
        '=; expires=' +
        new Date().toUTCString() +
        '; domain=' +
        d.join('.') +
        ' ;path=';
      // eslint-disable-next-line no-restricted-globals
      const p = location.pathname.split('/');
      document.cookie = cookieBase + '/';
      while (p.length > 0) {
        document.cookie = cookieBase + p.join('/');
        p.pop();
      }
      d.shift();
    }
  }
}

export const shortenSummary = (data, limit) => {
  return data?.length > limit ? data.substr(0, limit - 1) + '...' : data;
};

export const calculatePayableAmount = (
  amount,
  isPremiumMember,
  membershipPercentageOff = 0
) => {
  const percentageOff = isPremiumMember ? membershipPercentageOff : 0;
  // if (hasPromo && percentageOff && isPremiumMember) {
  //   return Math.round(promoFee - (promoFee * percentageOff) / 100);
  // }
  if (percentageOff) {
    return Math.round(amount - (amount * percentageOff) / 100);
  } else {
    return amount;
  }
};

export const generateRazorpayPaymentUrl = (
  url,
  userProfile,
  consultationFee
) => {
  const paymentUrl = `${url}?name=${
    userProfile
      ? userProfile?.firstName + '%20' + userProfile?.lastName
      : new URLSearchParams(window.location.search).get('invitee_full_name') ||
        ''
  }&email=${
    userProfile
      ? userProfile?.email
      : new URLSearchParams(window.location.search).get('invitee_email') || ''
  }&phone=${userProfile?.mobile || ''}&amount=${consultationFee}`;
  localStorage.setItem('paymentUrl', paymentUrl);
  return paymentUrl;
};

export const generateCalendlyUrl = (url, userProfile) => {
  return userProfile
    ? `${url}?name=${
        userProfile?.firstName ?? '' + '%20' + userProfile?.lastName ?? ''
      }&email=${userProfile?.email ?? ''}&a1=91${userProfile?.mobile ?? ''}`
    : url;
};

export function getCookie(cname = 'authToken') {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  const tokenCookie = ca.filter(el => el.includes(name));
  const tokenArr =
    (tokenCookie && tokenCookie[0] && tokenCookie[0].split('=')) || null;
  if (tokenArr) {
    return tokenArr[1];
  }
  return '';
}

export const isEmpty = (string) =>
  string === null || string === undefined || string === "";