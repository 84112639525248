import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import commonString from '../../common/stringLiterals';
import './index.scss';
import Header from '../../components/Header';
import InputField from '../../components/InputField';
import NoUserPhoto from '../../assets/images/NoUser.svg';
import Footer from '../../components/Footer';
import {
  getProfileDetails
} from '../../services/profileService';

import { validateName, validateEmail } from '../../common/validateInputField';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function DoctorProfilePage() {
  const authData = useSelector(store => store.auth);
  const [image, setImage] = useState(null);
  const [emailVerified, setEmailVerified] = useState(null);
  const [formValue, setFormValue] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const [initialForm, setInitailForm] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const user_image = useRef(null);
  const [apiResponseErrors, setApiResposeErrors] = useState({
    email: '',
    mobileNo: '',
  });
  const [profileData, setProfileData] = useState({});
  const [error, setError] = useState({
    emailError: '',
  });
  const [inputError, setInputError] = useState(true);
  const InputFieldError = e => {
    if (e !== '') {
      setInputError(true);
    } else {
      setInputError(false);
    }
  };
  const getProfileApi = () => {
    getProfileDetails()
      .then(res => {
        const data = res.data;
        if (data) {
          setProfileData(data);
          setFormValue({
            ...formValue,
            phone:
              data.mobile !== null
                ? '+' + data.countryCode + '-' + data.mobile
                : '',
            name: data.completeName !== null ? data.completeName : '',
            email: data.email !== null ? data.email : '',
          });
          setInitailForm({
            ...initialForm,
            phone:
              data.mobile !== null
                ? '+' + data.countryCode + '-' + data.mobile
                : '',
            name: data.completeName !== null ? data.completeName : '',
            email: data.email !== null ? data.email : '',
          });
          setImage(data?.image?.url);
          setEmailVerified(data?.isEmailVerified);

        }
      })
      .catch(err => { });
  };

  useEffect(() => {
    getProfileApi();
  }, []);

  const redirectToWebsite = () => {
    // const queryParams = new URLSearchParams(window.location.search);
    // if (queryParams.get('redirectUrl')) {
    //   window.open(queryParams.get('redirectUrl'), '_self');
    // } else {
      window.open(
        `${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}/doctor-appointments`,`_self`
      );
    // }
    // window.location.assign(`${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}`)
  };

  return (
    <>
      <Helmet title={`Profile Page${commonString.title}`} />
      {profileData && profileData.id && (
        <Header
          authData={authData}
          profileData={profileData}
          headerImage={image}
          completeName={initialForm.name}
        />
      )}
      <div className='profile'>
        <div className='profile-container'>
          <div className='profile-container-heading'>
            <h1 className='heading-title'>Your Profile </h1>
            <p className='subheading'>
              <span className='m-0'>*</span>Marked fields are mandatory
            </p>
          </div>

          <div className='profile-container-fields'>
            <div className='profile-container-fields-photoContainer'>
              <div className='profile-container-fields-photoContainer-imageBox'>
                <img
                  src={image ? image : NoUserPhoto}
                  alt='no user'
                  id='userPhoto'
                  ref={user_image}
                ></img>
              </div>
            </div>
            <div className='profile-container-fields-form'>
              <div className='profile-container-fields-form-input'>
                <InputField
                  data={{
                    type: 'text',
                    placeholder: '',
                    name: 'name',
                    label: 'Full Name',
                    value: formValue.name,
                    valfn: validateName,
                    inputName: 'name',
                    error: e => InputFieldError(e),
                    disabled: true
                  }}
                />
                <InputField
                  data={{
                    type: 'text',
                    placeholder: '',
                    name: 'phone',
                    label: 'Phone Number',
                    value: formValue.phone,
                    // valfn: validatePhone,
                    error: e => InputFieldError(e),
                    permission: true,
                    disabled: true,
                    apiResponseErrorNumber: apiResponseErrors.mobileNo,
                    disabled: true
                  }}
                />
                <InputField
                  data={{
                    type: 'email',
                    placeholder: '',
                    name: 'email',
                    label: 'Email',
                    value: formValue.email,
                    valfn: validateEmail,
                    error: e => InputFieldError(e),
                    emailVerfied: emailVerified,
                    apiResponseErrorEmail: apiResponseErrors.email,
                    disabled: true
                  }}
                />
              </div>
              <div className='errorEmail'>
                {error && error?.emailError ? `${error.emailError}` : ''}
              </div>
              <div className='profile-container-fields-form-submit'>
                <a href={`${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}/doctor-appointments`}>
                  <button
                    className={`profile-container-fields-form-submit-todash profile-container-fields-form-submit-btn save-active `}
                    // onClick={() => redirectToWebsite()}
                  >
                    Show My Appointments
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default DoctorProfilePage;
