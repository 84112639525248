import React from 'react';
import { Route, Redirect, Link } from 'react-router-dom';
import { getCookie } from '../../services'

const PublicRoute = ({ component: Component, ...props }) => {

    return (
        <Route {...props} render={() =>
            getCookie('authToken') ?
                window.location.href = process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL
                : <Component {...props} />
        } />
    );
};

export default PublicRoute;