import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import LandingPage from "../containers/Landing";
import ProfilePage from "../containers/ProfilePage";
import Error from "../containers/404";

import PublicRoute from "./../hoc/PublicRoute";
import PrivateRoute from "./../hoc/PrivateRoute";

import { trackGAPageView } from "../utilities/analyticsService";

const AppRouter = () => {
  /** Grab the fields from location to track history changes */
  const { pathname, search } = useLocation();

  /** Initialize GA tracking metrics */
  useEffect(() => {
    ReactGA.initialize([{
      trackingId: process.env.REACT_APP_GA_ID,
    }]);
  }, []);
  
  /** Track all changes to pathname by using location */
  useEffect(() => {
    trackGAPageView(pathname, search);
  }, [pathname, search]);

  return (
    <Switch>
      <PublicRoute
        exact
        path="/"
        private={false}
        component={LandingPage}
        signUp={false}
      />
      <PublicRoute
        exact
        path="/sign-up"
        private={false}
        component={LandingPage}
        signUp={true}
      />
      <PrivateRoute
        exact
        path="/profile"
        component={ProfilePage}
        private={true}
      />
      {/* <PrivateRoute
        exact
        path="/complete-profile"
        component={FirstTImeProfilePage}
        private={true}
      />
      <PrivateRoute
        exact
        path="/dashboard"
        component={Dashboard}
        private={true}
      /> */}
      <PublicRoute
        exact
        component={LandingPage}
        signUp={false}
        path="/login"
        private={false}
      />
      {/* <PrivateRoute
        exact
        path='/cc-appointment-booking-completed'
        component={AppointmentBookingCompleted}
        private={true}
      /> */}
      <Route exact path="*" component={Error} />
    </Switch>
  );
};

export default AppRouter;
