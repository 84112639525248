const BackendConstants = {
  //Webinars
  webinars: "/webinars/curated",

  //Videos
  videos: "/videos/curated",

  //Question
  question: "/questions/curated",

  //Wellness
  wellness: "/support-groups/curated",

  //Blogs
  blogs: "/blogs/curated",

  //Specialization
  specializations: "/specializations",

  //Physicians
  users: "/users",

  //Aoc
  aocs: "/aocs",

  //Auth
  signup: 'auth/customers/signup',
  verifyOtp: 'auth/verify-mobile-otp',
  login: 'auth/customers/login',
  sendEmail: 'auth/send-email-otp',
  verifyEmailOtp: 'auth/verify-email-otp',

  //Profile
  userProfile: 'customers/profile',

  // Uploads
  uploads: "/uploads",

  //Membership
  membershipDetails: "/memberships",

  //testimonials
  testimonials: "/testimonials"
};

export default BackendConstants;
