import { axiosInstance } from "./index";

import BackendConstants from "../common/backendConstants";

//Get Videos
export const getAllVideos = (params) => {
  return axiosInstance.get(BackendConstants.videos, { params: params });
};

//Get Questions
export const getAllQuestions = (params) => {
  return axiosInstance.get(BackendConstants.question, { params: params });
};

//Get Webinars
export const getAllWebinars = (params) => {
  return axiosInstance.get(BackendConstants.webinars, { params: params });
};

//Get Wellness
export const getAllWellnessPrograms = (params) => {
  return axiosInstance.get(BackendConstants.wellness, { params: params });
};

//Get Users
export const getAllUsers = (params) => {
  return axiosInstance.get(BackendConstants.users, { params: params });
};

//Get Specializations
export const getAllSpecializations = (params) => {
  return axiosInstance.get(BackendConstants.specializations, {
    params: params,
  });
};

//Get Blogs
export const getAllBlogs = (params) => {
  return axiosInstance.get(BackendConstants.blogs, { params: params });
};

//Get Aocs
export const getAllAocs = (params) => {
  return axiosInstance.get(BackendConstants.aocs, { params: params });
};


//Get MemberShip Data
export const getMemberShipData = () => {
  return axiosInstance.get(BackendConstants.membershipDetails);
};

//Get testimonials
export const getTestimonial = (params) => {
  return axiosInstance

    .get(BackendConstants?.testimonials, {
      params: params,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};