import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import logo from '../../assets/images/footer/logo.svg';
import twitter from '../../assets/images/footer/twitter.svg';
import instagram from '../../assets/images/footer/instagram.svg';
import linkedin from '../../assets/images/footer/linkedin.svg';
import youtube from '../../assets/images/footer/youtube.svg';
import facebook from '../../assets/images/footer/facebook.svg';

import './index.scss';

const Footer = (props) => {
  return (
    <footer className='footer'>
      <div className='footer-head'>
        <img src={logo} />

        <div className='social-links'>
          <a
            href='https://twitter.com/HerProactive'
            target='_blank'
            rel='nofollow noopener'
          >
            <img className='img' src={twitter} alt='' />
          </a>

          <a
            href='https://www.instagram.com/proactiveforher/'
            target='_blank'
            rel='nofollow noopener'
          >
            <img className='img' src={instagram} alt='' />
          </a>

          <a
            href='https://www.linkedin.com/company/proactiveforher/'
            target='_blank'
            rel='nofollow noopener'
          >
            <img className='img' src={linkedin} alt='' />
          </a>

          <a
            href='https://www.youtube.com/channel/UC6awC37SlriJTO3Mj-aXfdQ'
            target='_blank'
            rel='nofollow noopener'
          >
            <img className='img' src={youtube} alt='' />
          </a>

          <a
            href='https://www.facebook.com/proactiveforher/?eid=ARBOEZQG5jk8MqVy1_dkE5SWe1aZh6iVRJ4CNfc2AlW_d4oKslHdFP1TOpa1LqHGK7eKHdarQEhFmfjz'
            target='_blank'
            rel='nofollow noopener'
          >
            <img className='img' src={facebook} alt='' />
          </a>
        </div>
      </div>

      <div className='footer-content'>
        <Row>
          <Col xs={6} sm={3} className='footer-content-list'>
            <div className='footer-heading'>Contact</div>
            <p className='footer-text'>
              No. 610, 12th main,
              <br /> HAL 2nd stage,
              <br /> Indiranagar, Bengaluru,
              <br /> Karnataka 560038
            </p>
            <p className='footer-text'>
              <a href='tel:+919882080808'>+919882080808</a>
              <br />
              <a href='mailto:connect@proactiveforher.com'>
                connect@proactiveforher.com
              </a>
            </p>
          </Col>
          <Col xs={6} sm={3} className='footer-content-list'>
            <div className='footer-heading'>Services</div>
            <Link className='footer-text' to='/clinic'>
              Health Clinics
            </Link>
            <Link className='footer-text' to='/diagnostic'>
              Diagnostics
            </Link>
            <Link className='footer-text' to='/pcos'>
              Programs
            </Link>
          </Col>
          <Col xs={6} sm={3} className='footer-content-list'>
            <div className='footer-heading'>Terms</div>
            <Link className='footer-text' to='/terms-and-conditions'>
              Terms of Use
            </Link>
            <Link className='footer-text' to='/privacy-policy'>
              Privacy Policy
            </Link>
            <Link className='footer-text' to='/cancellation-and-refund-policy'>
              Cancellation and Refund Policy
            </Link>
            <a
              className='footer-text'
              href='https://www.linkedin.com/jobs/search/?geoId=102713980&keywords=proactive%20for%20her&location=India'
            >
              Careers
            </a>
          </Col>
          <Col xs={6} sm={3} className='footer-content-list'>
            <div className='footer-heading'>Resources</div>
            <Link className='footer-text' to='/blogs'>
              Blogs
            </Link>
            <Link className='footer-text' to='/webinars'>
              Webinars
            </Link>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
