import axios from 'axios';
import { deleteCookie } from '../utilities/utils';
// Begin Axios Base Instance
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_PATH,
  timeout: 30000,
});

// Begin Axios Request Interceptors
axiosInstance.interceptors.request.use((req) => {
  return requestInterceptor(req);
});

export function getCookie(cname) {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  const tokenCookie = ca.filter((el) => el.includes('authToken='));
  const tokenArr =
    (tokenCookie && tokenCookie[0] && tokenCookie[0].split('=')) || null;
  if (tokenArr) {
    return tokenArr[1];
  }
  return '';
}

const requestInterceptor = async (request) => {
  // console.log("Intercepted Client Request", request);

  const authToken = getCookie('authToken');

  if (authToken) {
    request.headers.common['Authorization'] = `Bearer ${authToken}`;
  }
  return request;
};

// Begin Axios Response Interceptors
axiosInstance.interceptors.response.use(
  (res) => {
    return responseInterceptors(res, null);
  },
  (err) => {
    return responseInterceptors(null, err);
  }
);

const responseInterceptors = async (response, error) => {
  if (response) {
    //console.log('Intercepted Server Response', response);
    return response.data;
  } else if (error) {
    if (error.response.status === 401) {
      deleteCookie();
    }
    //console.log('Intercepted Server Response', error);
    return Promise.reject(error);
  }
};
