import Analytics from "analytics";
import googleTagManager from "@analytics/google-tag-manager";

const analytics = Analytics({
  app: "ProactiveForHer-app",
  plugins: [
    googleTagManager({
      containerId: process.env.REACT_APP_GTM_TRACKING_ID
    }),
  ],
});

export const trackGTMEvent = (eventName) => {
  const trackedEventObj = {};
  for (const [key, value] of Object.entries(eventName)) {
    if (value) {
      Object.assign(trackedEventObj, { [key]: value });
    }
  }

  console.log("trackedEventObj",trackedEventObj)

  /* Track a custom event */
  if (eventName) {
    analytics.track(eventName, { ...trackedEventObj });
    window.dataLayer.push({
      event: eventName, ...trackedEventObj
    })
  }
};
