import ReactGA from "react-ga4";

/** Send pageview with a custom path
 * @param pathname - URL
 * @param search - query param
 */
export const trackGAPageView = (pathname, search) => {
  ReactGA.send({ hitType: "pageview", page: pathname + search });
}

