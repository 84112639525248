import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Header from '../../components/Header/index';
import Footer from '../../components/Footer/index';
import emoji from '../../assets/images/emoji.png';
import PrimaryButton from '../../components/PrimaryButton';
import commonString from '../../common/stringLiterals';

import './styles.scss';

const ErrorPage = (props) => {
  return (
    <div className='error-wrapper'>
      <Helmet title={`Page Not Found${commonString.title}`} />
      <Header />
      <div className='webinar-section'>
        <section className='error-section'>
          <img src={emoji} alt='ERROR' />
          <h3 style={{ textAlign: 'center' }}>The page can’t be found.</h3>
          <p style={{ textAlign: 'center', marginTop: '20px' }}>
            It looks like nothing was found at this location.
          </p>

          <Link to='/' style={{ marginTop: '20px' }}>
            <button className='button-primary'>Take me to homepage</button>
          </Link>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default ErrorPage;
