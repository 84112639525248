import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import BannerImg from "../../assets/images/banner.png";
import "./styles.scss";
import commonString from "../../common/stringLiterals";
import PrimaryButton from "../../components/PrimaryButton";
import Input from "../../components/Input";
import LandingCard from "../../components/LandingCard";
import CountryPicker from "../../components/CountryPickerDropdown";
import OtpInputModal from "../../components/modals/OtpInputModal";
import { validatePhone } from "../../common/validateInputField";
import proActive from "../../assets/images/logoOrange.svg";
import { PhoneNumberUtil } from "google-libphonenumber";
import Header from "../../components/Header";
import {
  signupCustomer,
  sendVerificationOtp,
  loginCustomer,
} from "../../services/authService";
import { useDispatch, useSelector } from "react-redux";
import { trackEvent, trackEventWithUserDetails } from "../../services/moengageServices";
import { trackGTMEvent } from "../../services/gtmService";

function LandingPage(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const googleLibValidation = () => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const number = phoneUtil.parse(mobileNo, countryIsoCode);
    const result = phoneUtil.isValidNumber(number);
    return result;
  };
  const [countryCode, setCountryCode] = useState("+91");
  const [isSignup, setIsSignup] = useState(false);
  const [countryIsoCode, setCountryIsoCode] = useState("IN");
  const [mobileNo, setMobileNo] = useState("");
  const [showList, setShowList] = useState(false);
  const [isOtpModal, setOtpModal] = useState(false);
  const [resendText, setResendText] = useState(null);
  const [error, setError] = useState({
    error: true,
    message: "",
  });

  const [otpErrorResponse, setOtpErrorResponse] = useState(null);
  const userData = useSelector((state) => state.auth);
  const handleFieldUpdate = (fieldName, fieldValue) => {
    if (fieldName === "mobileNumber") {
      const numberRegex = /^\d+$/;
      if (numberRegex.test(fieldValue) || !fieldValue.length) {
        setMobileNo(fieldValue);
      }
    }
  };
  const getSubDomain = (str) => {
    const splitFirst = str.split("//");
    const splitSecond = splitFirst[1].split(".");
    return splitSecond[0];
  };


  const redirectToWebsite = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("redirectUrl")) {
      window.open(queryParams.get("redirectUrl"), "_self");
    }
    else {
      window.location.assign(
        `${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}`
      );
    }
  };

  const requestOtpSignup = () => {
    setOtpErrorResponse(null);
    const validation = validate(countryCode, mobileNo);
    if (!validation && mobileNo) {
      const numberValid = googleLibValidation();
      const memberCode = props.location.search.split("=");
      if (numberValid) {
        const subDomain = getSubDomain(window.location.href);
        let payload = {
          mobile: mobileNo,
          country_code: countryCode.substr(1),
        };
        if (memberCode.length > 1) {
          payload.membership_code = memberCode[1];
        }
        if (subDomain) {
          payload.sub_domain = subDomain;
        }

        signupCustomer(payload)
          .then((res) => {
            if ((res.code === 201 || res.code === 200) && !isOtpModal) {
              setOtpModal(true);
              return;
            } else if ((res.code === 201 || res.code === 200) && isOtpModal) {
              setResendText("Your OTP has been resent.");
              return;
            }
          })
          .catch((err) => {
            const errorData = err.response.data;
            let tempErr = {
              error: true,
              message: errorData.message,
            };

            if (!isOtpModal) {
              setError({ ...tempErr });
            } else {
              setOtpErrorResponse(errorData);
            }
          });
        setIsSignup(true);
        setOtpModal(true);
      } else {
        let err = {
          error: true,
          message: "We couldn’t find the phone number you entered.",
        };

        setError({ ...err });
      }
    }
  };

  const requestOtpLogin = () => {
    trackEvent({
      event:"Button_Clicked_Custom",
      source_page_name:"login page",
      source_page_url:window.location.href,
      next_page_name:"OTP Modal",
      next_page_url:window.location.href,
      button_name:"Send_OTP",
      mobile: mobileNo,
    })
     //sendOtp-gtm
     trackGTMEvent({
      event:"Initiate_Registration",
      refering_url: window.location.href,
      current_url: window.location.href,
      mobile: mobileNo,
    });

    const validation = validate(countryCode, mobileNo);
    setOtpErrorResponse(null);
    if (!validation && mobileNo) {
      const numberValid = googleLibValidation();
      if (numberValid) {
        let payload = {
          mobile: mobileNo,
          country_code: countryCode.substr(1),
        };
        loginCustomer(payload)
          .then((res) => {
            if (
              (res.code === 201 || res.code === 200) &&
              res.message == "doctor"
            ) {
              setOtpModal(false);
              return;
            } else if ((res.code === 201 || res.code === 200) && !isOtpModal) {
              setOtpModal(true);
              return;
            } else if ((res.code === 201 || res.code === 200) && isOtpModal) {
              setResendText("Your OTP has been resent.");
              return;
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (err?.response?.status === 400) {
                requestOtpSignup();
              }
            } else {
              window.location.reload()
            }
          });
      } else {
        let err = {
          error: true,
          message: "We couldn’t find the phone number you entered.",
        };
        setError({ ...err });
      }
    }
  };

  const validate = (code, number) => {
    let codeValue = code.substring(1);
    let err = validatePhone(codeValue, number);
    if (err && err.error) {
      setError({ ...err });
      return err.error;
    } else {
      setError({ ...err });
      return err.error;
    }
  };

  const verificationOTP = (otp) => {
    let data = {
      mobile: mobileNo,
      country_code: countryCode.substr(1),
      otp: otp,
    };
    setOtpErrorResponse(null);
    sendVerificationOtp(data)
      .then((res) => {
        const date = new Date();
        const expiry =
          date.getTime() +
          parseInt(process.env.REACT_APP_WEBSITE_COOKIE_TIMEOUT);
        date.setTime(expiry);
        const change = date.toUTCString();
        trackEvent({
          event: 'Login_Custom',
          firstName: res?.data?.firstName,
          lastName: res?.data?.lastName,
          email: res?.data?.email,
          mobile: res?.data?.mobile,
          id: res?.data?.id,
        });
        trackEventWithUserDetails({
          firstName: res?.data?.firstName,
          lastName: res?.data?.lastName,
          email: res?.data?.email,
          mobile: res?.data?.mobile,
          id: res?.data?.id,
        });
        setTimeout(() => {
          if (res.data) {
            const data = res.data;
            localStorage.setItem("auth", res.meta.token);
            document.cookie = `authToken=${res.meta.token};expires=${change};domain=${process.env.REACT_APP_CROSS_COOKIE_DOMAIN}`;
            const queryParams = new URLSearchParams(window.location.search);
            if (data.roleId === 2) {
              if (process.env.REACT_APP_ENV === "local") {
                if (queryParams.get("redirectUrl")) {
                  {
                    history.push(
                      isSignup
                        ? "/profile?redirectUrl=" +
                        queryParams.get("redirectUrl")
                        : "/profile?redirectUrl=" + queryParams.get("redirectUrl")
                    );
                  }
                } else {
                  {
                    history.push("/profile");
                  }
                }
              } else {
                {
                  window.open(
                    isSignup
                      ?
                      process.env.REACT_APP_BASE_URL +
                      "/profile"
                      :
                      process.env.REACT_APP_BASE_URL +
                      "/profile",
                    "_self"
                  );
                }
              }
            } else if (
              data.isMobileVerified === 1 &&
              data.firstName &&
              data.lastName
            ) {
              window.open(
                queryParams.get("redirectUrl") ?
                  queryParams.get("redirectUrl")
                  :
                  process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL,
                "_self"
              )
            }
            else {
              if (process.env.REACT_APP_ENV === "local") {
                if (queryParams.get("redirectUrl")) {
                  {
                    history.push(
                      isSignup
                        ? "/profile?redirectUrl=" +
                        queryParams.get("redirectUrl")
                        : "/profile?redirectUrl=" + queryParams.get("redirectUrl")
                    );
                  }
                } else {
                  {
                    history.push("/profile");
                  }
                }
              } else {
                if (queryParams.get("redirectUrl")) {
                  window.open(
                    isSignup
                      ?
                      process.env.REACT_APP_BASE_URL +
                      "/profile?redirectUrl=" +
                      queryParams.get("redirectUrl")
                      :
                      process.env.REACT_APP_BASE_URL +
                      "/profile?redirectUrl=" +
                      queryParams.get("redirectUrl"),
                    "_self"
                  );
                } else {
                  if (data.roleId == 2) {
                    {
                      window.open(
                        isSignup
                          ?
                          process.env.REACT_APP_BASE_URL +
                          "/profile"
                          :
                          process.env.REACT_APP_BASE_URL +
                          "/profile",
                        "_self"
                      );
                    }
                  } else {
                    {
                      window.open(
                        isSignup
                          ?
                          process.env.REACT_APP_BASE_URL +
                          "/profile"
                          :
                          process.env.REACT_APP_BASE_URL +
                          "/profile",
                        "_self"
                      );
                    }
                  }
                }
              }
            }
          }
        }, 2000);
      }).catch((err) => {
        if (err.response.status === 400) {
          requestOtpSignup();
        }
      });
  };

  return (
    <>
      <Helmet
        title={`Login${commonString.title}`}
      />
      <Header setError={setError} />
      <div
        className="landing"
        onClick={(e) => {
          if (
            e.target.id !== "drop" &&
            e.target.id !== "input-drop" &&
            showList
          ) {
            setShowList(false);
          }
        }}
      >
        <div className="row landing-content mx-0">
          <div className="col-lg-5 col-sm-12 col-md-12 p-0 display-content landing-content-wrapper d-flex flex-column justify-content-between">
            <div className="px-5 mb-1 landing-content-wrapper-texts d-flex flex-column justify-content-between">
              <div className="container-fluid px-3 landing-content-wrapper-texts-wrapper">
                <div className="content-height">
                  <img
                    src={proActive}
                    alt="Proactive Banner"
                    className="landing-content-wrapper-proActive"
                  />
                </div>
                <div className="mb-2 d-flex flex-row justify-content-center">
                  <LandingCard />
                </div>
              </div>
            </div>
            <div className="landing-content-wrapper-image">
              <img
                src={BannerImg}
                alt="Proactive Banner"
                className="image-set"
              />
            </div>
          </div>
          <div className="col-12 col-lg-7 col-sm-12 col-md-12 p-0">
            {isOtpModal ? (
              <OtpInputModal
                otpErrorResponse={otpErrorResponse}
                setOtpErrorResponse={setOtpErrorResponse}
                verifyOtp={verificationOTP}
                isOtpModal={isOtpModal}
                setOtpModal={setOtpModal}
                resendOtp={requestOtpLogin}
                resendText={resendText}
                setResendText={setResendText}
              />
            ) : (
              ""
            )}

            <div className="landing-information-wrapper d-flex flex-column align-items-center">
              <div className="d-flex flex-column justify-content-center padding-set wrap">
                <div className="align-content">
                  <h1 className="landing-information-wrapper-title font-weight-bold mb-4">
                    {props.signUp ? "Let’s get started" : "Get the health care you deserve!"}
                  </h1>
                  <p className="landing-information-wrapper-subtext mb-4">
                  Non-judgemental and convenient doctor consultations, diagnostic lab tests and more.
                  </p>
                </div>
                {error && error.error ? (
                  <small className="error">{error.message}</small>
                ) : null}
                <div className="d-flex flex-row ">
                  <div className="col-12 col-md-12 col-lg-12 p-0">
                    <div className="dropdown-input-style">
                      <CountryPicker
                        showList={showList}
                        setShowList={setShowList}
                        countryCode={countryCode}
                        setCountryCode={setCountryCode}
                        countryIsoCode={countryIsoCode}
                        setCountryIsoCode={setCountryIsoCode}
                      />
                      <Input
                        name="mobileNumber"
                        placeholder="Enter mobile number"
                        value={mobileNo}
                        handleChange={handleFieldUpdate}
                        maxLength={10}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="text-btn-right"
                >
                  <PrimaryButton
                    id="btn-send-otp"
                    value="Send OTP"
                    name="Login"
                    handleClick={requestOtpLogin}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LandingPage;
