import { useEffect, useRef, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import Toast from 'react-bootstrap/Toast';
import { toast } from 'react-toastify';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import 'react-toastify/dist/ReactToastify.css';
import commonString from '../../common/stringLiterals';
import './index.scss';
import Header from '../../components/Header';
import InputField from '../../components/InputField';
import DoctorProfilePage from './DoctorProfilePage';
import NoUserPhoto from '../../assets/images/NoUser.svg';
import CalendarIcon from '../../assets/images/calendar.svg';
import 'react-datepicker/dist/react-datepicker.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMediaQuery } from 'react-responsive';
import Footer from '../../components/Footer';
import {
  getProfileDetails,
  updateProfileDetails,
} from '../../services/profileService';

import moment from 'moment';
import { cloneDeep } from 'lodash';
import successTick from '../../assets/images/successTick.svg';
import toastCross from '../../assets/images/toastCross.svg';
import { getMemberShipData } from '../../services/dashBoardService';
import ImageCapture from 'react-image-data-capture';
import { validateName, validateEmail } from '../../common/validateInputField';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { uploadImageService } from '../../services/uploadService';
import { trackEvent, trackEventWithUserDetails } from '../../services/moengageServices';
import editProfile from '../../assets/images/editProfile.svg';
import Drawer from 'react-bottom-drawer';
import DeleteDisable from '../../assets/images/deleteDisable.svg';
import Gallery from '../../assets/images/gallery.svg';
import Delete from '../../assets/images/delete.svg';
import { trackGTMEvent } from '../../services/gtmService';

function ProfilePage() {
  const authData = useSelector((store) => store.auth);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [imageIdentifier, setImageIdentifier] = useState(null);
  const [hasFileModified, setFileModified] = useState(false);
  const [hasImageModified, setImageModified] = useState(false);
  const [emailVerified, setEmailVerified] = useState(null);
  const [hasDobModified, setHasDobModified] = useState(false);
  const [premiumMemeber, setPremiumMemeber] = useState(null);
  const [isDashboardVisible, setIsDashboardVisible] = useState(null);
  const [numberOfCCAppointments, setNumberOfCCAppointments] = useState(null);
  const [totalCCAppointments, setTotalCCAppointments] = useState(null);
  const [otpErrorResponse, setOtpErrorResponse] = useState(null);
  const [companyAocOptions, setCompanyAocOptions] = useState([]);
  const [startDate, setStartDate] = useState();
  const [noImage, setNoImage] = useState(false);
  const [formValue, setFormValue] = useState({
    fname: '',
    lname: '',
    email: '',
    phone: '',
  });
  const [initialForm, setInitailForm] = useState({
    fname: '',
    lname: '',
    email: '',
    phone: '',
  });
  const [show, setShow] = useState(false);
  const [verifiedEmail, setVerifiedEmail] = useState(null);
  const user_image = useRef(null);
  const [showNoPhoto, setShowNoPhoto] = useState(true);
  const [verified, setVerified] = useState(false);
  const [apiResponseErrors, setApiResposeErrors] = useState({
    email: '',
    mobileNo: '',
  });
  const [profileData, setProfileData] = useState({});
  const [selectedAOC, setSelectedAOC] = useState([]);
  const [selectedAocInitial, setSelectedAocInitial] = useState([]);
  const [error, setError] = useState({
    emailError: '',
  });
  const [aocSelectedFlag, setaocSelectedFlag] = useState(false);
  const [pageType, setPageType] = useState(null);
  const [headerImage, setHeaderImage] = useState(null);
  const [showProceed, setShowProceed] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const openDrawer = useCallback(() => setIsVisible(true), []);
  const closeDrawer = useCallback(() => setIsVisible(false), []);
  const mobile = useMediaQuery({
    query: '(max-device-width: 574px)',
  });
  const userData = useSelector((state) => state.auth.userProfile);
  //Image
  const handleImageSelection = (targetValue, event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setFile(file);
      setImage(URL.createObjectURL(file));
      if (formValue.fname && formValue.lname && formValue.email)
        setFileModified(true);
      setImageModified(true);
      user_image.current.src = URL.createObjectURL(event.target.files[0]);
      user_image.current.style.width = '100%';
      user_image.current.style.height = '100%';
      user_image.current.style.objectFit = 'cover';
      event.target.value = '';
      setShowNoPhoto(false);
    }
  };

  //Upload Image
  const uploadImage = () => {
    return uploadImageService(file)
      .then((response) => {
        if (response) {
          if (response.code === 200) {
            return response;
          }
        }
      })
      .catch((errorData) => {
        console.log(errorData);
      });
  };

  const onChangeFormValue = (event) => {
    setFormValue({ ...formValue, [event.target.name]: event.target.value });
  };

  const [inputError, setInputError] = useState(true);

  const deleteFile = () => {
    user_image.current.src = NoUserPhoto;
    user_image.current.style.width = '100%';
    user_image.current.style.height = '100%';
    user_image.current.style.objectFit = 'contain';
    user_image.current.style.borderRadius = '0%';
    setShowNoPhoto(true);
    if (image) {
      toast('Profile picture Deleted!');
      setNoImage(true);
      setIsVisible(false);
    }
  };

  const InputFieldError = (e) => {
    if (e !== '') {
      setInputError(true);
    } else {
      setInputError(false);
    }
  };

  // const [showEmailModal, setShowEmailModal] = useState(false);

  // //Send Email Verifciation Otp
  // const verificationOTP = (otpValue) => {
  //   let data = {
  //     otp: otpValue,
  //   };
  //   sendVerificationOtpEmail(data)
  //     .then((res) => {
  //       if (res.code === 200) {
  //         setEmailVerified(true);
  //         setVerifiedEmail(formValue.email);
  //         setShowEmailModal(false);
  //         setFileModified(false)
  //         setHasDobModified(false)
  //         setShowProceed(true)
  //         updateProfile();
  //       }
  //     })
  //     .catch((err) => {
  //       const errorData = err.response.data;
  //       setOtpErrorResponse(errorData);
  //     });
  // };

  const handleSaveInformation = (e) => {
    setError({ emailError: '' });
    setOtpErrorResponse(null);
    e.preventDefault();
    // if (
    //   emailVerified === 0 ||
    //   emailVerified === null ||
    //   (emailVerified === 1 && verifiedEmail !== formValue.email)
    // ) {
    //   requestEmailOtp();
    //   setShowEmailModal(true);
    // } else {
    updateProfile();
    setHasDobModified(false);
    // setShowProceed(true)

    // }
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  const [dobBlur, setDobBlur] = useState(false);

  useEffect(()=>{
    let phone = formValue.phone.split('-');
    let numberVal = phone[1];
  },[])

  useEffect(() => {
    if (startDate === null) {
      return;
    } else if (!moment(startDate).isValid()) {
      setError({
        ...error,
        dob: 'Incorrect date format',
      });
    } else {
      setError({
        ...error,
        dob: '',
      });
    }
  }, [startDate]);

  const isButtonClickable = () => {
    if (error?.dob) {
      return true;
    }

    if (
      inputError ||
      formValue.fname === '' ||
      formValue.fname === null ||
      formValue.phone === '' ||
      formValue.phone === null ||
      formValue.email === '' ||
      formValue.email === null ||
      formValue.lname === '' ||
      formValue.lname === null ||
      startDate === undefined ||
      // (premiumMemeber === 1 && !selectedAOC.length) ||
      hasFileModified ||
      hasDobModified
    ) {
      if (hasFileModified || hasDobModified) {
        return false;
      } else {
        return true;
      }
    } else if (
      initialForm.fname !== formValue.fname ||
      initialForm.lname !== formValue.lname ||
      initialForm.email !== formValue.email
    ) {
      return false;
    } else if (hasFileModified) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    isButtonClickable();
  }, [hasFileModified]);

  const fetchMembershipDetails = () => {
    getMemberShipData()
      .then((res) => {
        setTotalCCAppointments(res?.data?.defaultMaxAppointments);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const getProfileApi = () => {
    getProfileDetails()
      .then((res) => {
        const data = res.data;
        if (data) {
          setProfileData(data);
          let tempArr = [];
          if (data?.aocs) {
            data?.aocs.map((item) => {
              tempArr.push(item.id);
            });
            setSelectedAocInitial(tempArr);
            setaocSelectedFlag(true);
          }
          setVerifiedEmail(data.email);
          setFormValue({
            ...formValue,
            phone:
              data.mobile !== null
                ? '+' + data.countryCode + '-' + data.mobile
                : '',
            fname: data.firstName !== null ? data.firstName : '',
            lname: data.lastName !== null ? data.lastName : '',
            email: data.email !== null ? data.email : '',
          });
          setInitailForm({
            ...initialForm,
            phone:
              data.mobile !== null
                ? '+' + data.countryCode + '-' + data.mobile
                : '',
            fname: data.firstName !== null ? data.firstName : '',
            lname: data.lastName !== null ? data.lastName : '',
            email: data.email !== null ? data.email : '',
          });
          setStartDate(data.dob ? moment(data.dob).toDate() : null);
          if (data.dob) {
            setDobBlur(true);
          }
          setImage(data?.image?.url);
          setImageIdentifier(data?.image?.id);
          setEmailVerified(data?.isEmailVerified);
          setCompanyAocOptions(data?.company?.aocs);
          setSelectedAOC(tempArr);
          setIsDashboardVisible(data?.company?.isDashboardVisible);
          setNumberOfCCAppointments(data?.numberOfCCAppointments);
          setPremiumMemeber(data?.isPremiumMember);
          if (
            (data?.firstName === '' || data?.firstName === null) &&
            (data?.lastName === '' || data?.lastName === null)
          ) {
            setPageType('signup');
          }
          if (data?.isEmailVerified === 1 || data?.isMobileVerified === 1) {
            setVerified(true);
            setShowProceed(false);
          }

          if (data?.isEmailVerified === 1 && data?.isMobileVerified === 1) {
            setVerified(true);
            setShowProceed(true);
          }
        }
        if (!data.firstName) {
          trackEvent({
            event: 'New_User_Custom',
            mobile: data.mobile,
            id: data?.id,
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            mobile: data?.mobile,
            id: data?.id,
          });
          trackEventWithUserDetails({
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            mobile: data?.mobile,
            id: data?.id,
          });
        }
      })
      .catch((err) => {});
  };

  const submitProfileInformation = (dataPayload) => {
    updateProfileDetails(dataPayload)
      .then((responseData) => {
        if (responseData) {
          if (responseData.code === 200) {
            if (hasFileModified) {
              getProfileApi();
            }
            setInitailForm(formValue);
            setSelectedAocInitial(selectedAOC);
            setPageType(null);
            setVerified(true);
            setShow(true);
            setFileModified(false);

            redirectToWebsite();
          }
        }
      })
      .catch((err) => {
        setVerified(false);
        const errorData = err.response?.data?.errors;
        const message = err.response?.data?.message;

        if (message && message.toLowerCase().search('email') > -1) {
          toast('Email already exists!!!');
        }

        if (errorData && Object.keys(errorData).length > 0) {
          for (let key in errorData) {
            const tempErr = cloneDeep(error);
            tempErr[key] = errorData[key][0];
            if (key !== 'dob') {
              toast(tempErr[key]);
            }
            setError(tempErr);
          }
        }
      });
      trackEvent({
        event: 'Sign_Up',
        ...dataPayload,
      });
    trackEventWithUserDetails({
      firstName: dataPayload?.first_name,
      lastName: dataPayload?.last_name,
      email: dataPayload?.email,
      mobile: dataPayload?.mobile,
      id: dataPayload?.id,
    });
    //signUp-gtm
    trackGTMEvent({
      event:"Sign_Up",
      refering_url: "/",
      current_url: window.location.href,
      firstName: dataPayload?.first_name,
      lastName: dataPayload?.last_name,
      email: dataPayload?.email,
      mobile: dataPayload?.mobile,
      id: dataPayload?.id,
    });
  };

  const updateProfile = () => {
    let phone = formValue.phone.split('-');
    let numberVal = phone[1];
    if (hasImageModified) {
      uploadImage()
        .then((responseDataImage) => {
          if (responseDataImage) {
            if (responseDataImage.code === 200) {
              const dataPayload = {
                first_name: formValue.fname,
                last_name: formValue.lname,
                mobile: numberVal,
                email: formValue.email,
                dob: startDate,
                aocs: selectedAOC,
                image_id: responseDataImage.data?.id,
              };
              setHeaderImage(responseDataImage.data?.url);
              submitProfileInformation(dataPayload);
            }
          }
        })
        .catch((uploadErrorData) => {
          console.log(uploadErrorData);
        });
    } else {
      const dataPayload = {
        first_name: formValue.fname,
        last_name: formValue.lname,
        mobile: numberVal,
        email: formValue.email,
        dob: startDate,
        aocs: selectedAOC,
        image_id: null,
      };

      submitProfileInformation(dataPayload);
    }
  };
  useEffect(() => {
    getProfileApi();
    fetchMembershipDetails();
  }, []);
  // const requestEmailOtp = () => {
  //   let payload = {
  //     email: formValue.email,
  //   };
  //   emailVerification(payload)
  //     .then((res) => {
  //       if (res.code === 201 || res.code === 200) {
  //         setApiResposeErrors({
  //           email: "",
  //           mobileNo: "",
  //         });
  //         setError({
  //           emailError: "",
  //         });
  //         setShowEmailModal(true);
  //         return;
  //       }
  //     })
  //     .catch((err) => {
  //       const errorData = err.response?.data?.errors;
  //       if (errorData && Object.keys(errorData).length > 0) {
  //         for (let key in errorData) {
  //           for (let e of errorData[key]) {
  //             let tempErr = {};
  //             tempErr[key] = e;
  //             setApiResposeErrors({
  //               ...apiResponseErrors,
  //               ...tempErr,
  //             });
  //           }
  //         }
  //       }
  //       if (err.response.data.message) {
  //         setApiResposeErrors({
  //           mobile: "",
  //           email: err.response.data.message,
  //         });
  //       }
  //     });
  // };
  const redirectToWebsite = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('redirectUrl')) {
      window.open(queryParams.get('redirectUrl'), '_self');
    } else {
      window.location.assign(
        `${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}`,
      );
    }
  };

  return (
    <>
      {profileData.roleId == 2 ? (
        <DoctorProfilePage />
      ) : (
        <>
          <Helmet title={`Profile Page${commonString.title}`} />
          {profileData && profileData.id && (
            <Header
              authData={authData}
              profileData={profileData}
              headerImage={image}
              firstName={initialForm.fname}
            />
          )}
          <div className="profile">
            <div className="profile-container">
              <div className="profile-container-heading">
                <h1 className="heading-title">
                  Complete your personal profile{' '}
                </h1>
                <h1 className="heading-title-mobile">Complete your personal profile</h1>
                <p className="subheading">
                  <span className="m-0">*</span>Marked fields are mandatory
                </p>
              </div>

              <div className="profile-container-fields">
                <div className="profile-container-fields-photoContainer">
                  <div style={{ position: 'relative' }}>
                    <div className="profile-container-fields-photoContainer-imageBox">
                      <img
                        src={image ? image : NoUserPhoto}
                        alt="no user"
                        id="userPhoto"
                        ref={user_image}
                      ></img>
                    </div>
                    <div className="profile-container-fields-photoContainer-edit">
                      <img src={editProfile} alt="edit" onClick={openDrawer} />
                      <Drawer
                        duration={50}
                        hideScrollbars={true}
                        onClose={closeDrawer}
                        isVisible={isVisible}
                        unmountOnExit={true}
                      >
                        <div className="profile-container-fields-photoContainer-editControls">
                          <p className="editTitle">Edit Image</p>
                          <div className="editOptions">
                            <div className="editItems" onClick={deleteFile}>
                              {imageIdentifier ? (
                                <img src={Delete} alt="remove" />
                              ) : (
                                <img src={DeleteDisable} alt="remove" />
                              )}

                              <span>Remove</span>
                            </div>

                            <label htmlFor="file">
                              <div
                                className="editItems"
                                onClick={() => setIsVisible(false)}
                              >
                                <img src={Gallery} alt="gallery" />
                                <span>Gallery</span>
                              </div>
                            </label>
                          </div>
                        </div>
                      </Drawer>
                    </div>
                  </div>
                  <div className="profile-container-fields-photoContainer-control">
                    {!imageIdentifier ? (
                      <p>
                        <label htmlFor="file" className="upload">
                          Upload image
                        </label>
                      </p>
                    ) : (
                      <p className="addedImage">
                        <label htmlFor="file" className="control">
                          Change Image
                        </label>
                        <label onClick={deleteFile} className="control ctr-del">
                          Remove Image
                        </label>
                      </p>
                    )}

                    <input
                      type="file"
                      accept=".jpg, .png, .jpeg"
                      name="image"
                      id="file"
                      style={{ display: 'none' }}
                      onChange={(e) => handleImageSelection(e.target.value, e)}
                    ></input>
                  </div>
                </div>
                <form className="profile-container-fields-form">
                  <div className="profile-container-fields-form-input">
                    <InputField
                      isLoginPage
                      data={{
                        type: "text",
                        placeholder: "Enter your First Name",
                        name: "fname",
                        onChange: onChangeFormValue,
                        label: 'First Name',
                        value: formValue.fname,
                        valfn: validateName,
                        inputName: 'fname',
                        error: (e) => InputFieldError(e),
                      }}
                    />
                    <InputField
                      isLoginPage
                      data={{
                        type: "text",
                        name: "lname",
                        placeholder: "Enter your Last Name",
                        onChange: onChangeFormValue,
                        label: 'Last Name',
                        value: formValue.lname,
                        valfn: validateName,
                        inputName: 'lname',
                        error: (e) => InputFieldError(e),
                      }}
                    />
                    <InputField
                      isLoginPage
                      data={{
                        type: "text",
                        placeholder: "Enter your Number",
                        name: "phone",
                        onChange: onChangeFormValue,
                        label: 'Number',
                        value: formValue.phone,
                        error: (e) => InputFieldError(e),
                        permission: true,
                        disabled: true,
                        apiResponseErrorNumber: apiResponseErrors.mobileNo,
                      }}
                    />

                    <InputField
                      isLoginPage
                      data={{
                        type: "email",
                        placeholder: "Enter your Email",
                        name: "email",
                        onChange: onChangeFormValue,
                        label: 'Email',
                        value: formValue.email,
                        valfn: validateEmail,
                        error: (e) => InputFieldError(e),
                        emailVerfied: emailVerified,
                        apiResponseErrorEmail: apiResponseErrors.email,
                      }}
                    />

                    <div className="inputfield">
                      <label className="mobile-dob">Date of Birth</label>
                      <div className="date-input">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            variant="inline"
                            value={startDate || null}
                            placeholder="DD/MM/YYYY"
                            format="dd/MM/yyyy"
                            onChange={(date) => {
                              setStartDate(date);
                              setHasDobModified(true);
                            }}
                            onBlur={() => {
                              setDobBlur(true);
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        {/* <input
                            className='input'
                            type='date'
                            required
                            name='date'
                            placeholder="DD/MM/YYYY"
                            value={startDate}
                            onChange={(e) => {
                              setStartDate(moment(e.target.value, 'YYYY-MM-DD').format('YYYY-MM-DD'));
                              setHasDobModified(true);
                            }}
                          /> */}
                      </div>
                      {dobBlur && error?.dob ? (
                        <small className="error">{error?.dob}</small>
                      ) : null}
                    </div>
                    {dobBlur && error?.dob ? (
                      <small className="inputfield-error-mobile">{error?.dob}</small>
                    ) : null}
                  </div>
                  <div className="errorEmail">
                    {error && error?.emailError ? `${error.emailError}` : ''}
                  </div>

                  <div className="profile-container-fields-form-submit">
                    <button
                      className={`profile-container-fields-form-submit-save ${
                        isButtonClickable() ? null : 'save-active'
                      } profile-container-fields-form-submit-btn`}
                      onClick={handleSaveInformation}
                      disabled={isButtonClickable()}
                      id="btn-sign-up"
                    >
                      <b>Save</b>
                    </button>
                    {/* <Link>
                      <button
                        className={`profile-container-fields-form-submit-todash profile-container-fields-form-submit-btn 
                          
                        ${showProceed && isButtonClickable()

                            ? "save-active"
                            // : !premiumMemeber && isButtonClickable()
                            //   ? "save-active"
                            : null

                          }`}

                        disabled={
                          (premiumMemeber === 0 || premiumMemeber === 1) && showProceed === true && isButtonClickable()
                            ? false
                            : true
                        }
                        onClick={() => redirectToWebsite()}
                      >
                        <b>Proceed</b>
                      </button>
                    </Link> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Footer />

          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            autohide
            style={{
              position: 'fixed',
              zIndex: '999',
              top: '90px',
              right: '20px',
              background: '#6BBEA6',
              color: 'white',
              fontSize: '16px',
              lineHeight: '22px',
            }}
          >
            <Toast.Body className="toast-body">
              <div className="toast-body-content">
                <div>
                  <img
                    src={successTick}
                    alt="updated PRofile"
                    className="mr-2"
                  />{' '}
                  Profile Saved!
                </div>
                <div>
                  <img
                    src={toastCross}
                    alt="closeToast"
                    className="toast-close"
                    onClick={() => setShow(false)}
                  />
                </div>
              </div>
            </Toast.Body>
          </Toast>
        </>
      )}
    </>
  );
}

export default ProfilePage;
