import { axiosInstance } from "./index";

import BackendConstants from "../common/backendConstants";

export const getProfileDetails = () =>{
    return axiosInstance.get(BackendConstants.userProfile,{});
}

export const updateProfileDetails = (data) =>{
    return axiosInstance.patch(BackendConstants.userProfile,data);
}