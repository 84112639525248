import { axiosInstance } from "./index";

import BackendConstants from "../common/backendConstants";

// Signup
export const signupCustomer = (data) => {
  return axiosInstance.post(BackendConstants.signup, data);
};

//Get VerifyOtpPhone
export const sendVerificationOtp = (data) => {
    return axiosInstance.patch(BackendConstants.verifyOtp, data);
};

export const emailVerification = (data) => {
    return axiosInstance.patch(BackendConstants.sendEmail, data);
};

//Get VerifyOtpEmail
export const sendVerificationOtpEmail = (data) => {
    return axiosInstance.patch(BackendConstants.verifyEmailOtp, data);
};

//Login
export const loginCustomer = (data) => {
    return axiosInstance.post(BackendConstants.login, data);
};