import { LOGIN, LOGOUT } from './constants';

const INITIAL_STATE ={
    isLoggedIn: false,
    phoneNumber: null,
}

const authReducer = (state = INITIAL_STATE, action) =>{
    switch(action.type){
        case LOGIN:
            return {
                ...state,
                isLoggedIn: true,
                phoneNumber: action.payload
            }
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false
            };
        default: return state;
    }
}

export default authReducer;