import { useEffect, useState } from "react";

import "./styles.scss";

const Input = (props) => {
  return (
    <div className="input-wrapper">
      {props.label && (
        <label className="input-label" htmlFor={props.name}>
          {props.label}
          {props.required === "yes" ? (
            <span className="text-danger">*</span>
          ) : (
            ""
          )}
        </label>
      )}
      <input
        name={props.name}
        value={props.value}
        type={props.type || "text"}
        placeholder={props.placeholder || ""}
        maxlength={props.maxlength}
        onChange={(event) =>
          props.handleChange(props.name, event.target.value, event)
        }
        className={
          props.classname ? `input-field ${props.classname}` : `input-field`
        }
        {...props}
        onBlur={props.validate}
      />
    </div>
  );
};

export default Input;
