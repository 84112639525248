import countryData from './dataManager';
import './styles.scss';
import {useState} from 'react';

const CountryPicker = (props) =>{
    const {showList, setShowList,countryCode,setCountryCode, countryIsoCode, setCountryIsoCode}= props
    const [data,setData] = useState(countryData);
    
    const [filteredData, setFilteredData] = useState(countryData);

    const handleSelectCountry = (codeValue, isoValue) =>{
        setCountryCode(codeValue);
        setCountryIsoCode(isoValue);
    }
    const handleChangeInput = (value) =>{
        setCountryCode(value);
        if(!value){
            setFilteredData(data);
            return ;
        }
        const tempData = data.filter((el)=>el.dialCode.includes(value));
        setFilteredData(tempData);
    }
    return (
        <>
        <div className='dropdown custom-dropdown-style' >
            <input type='text' 
                id="input-drop"
                className='extension-input' 
                value={countryCode} 
                placeholder='+91' 
                onFocus={()=>setShowList(true)}
                onChange={(e)=>handleChangeInput(e.target.value)}
                // onClick={()=>console.log('heelo')}
                className={props.className ? `input-field ${props.classname}` : `input-field`}
            />
            {showList ?<div className='dropdown-data' id='drop'>
            <ul className='list-wrapper'>
                {filteredData.map((item,index)=>{
                        return(
                            <li key={index} className='list-data' onClick={()=>handleSelectCountry(item.dialCode, item.isoCode)}>
                                <div className='code'>
                                {item.dialCode}
                                </div>
                                <div className='name'>
                                {item.name}
                                </div>
                                {/* <span className='dialcode'></span> &nbsp;&nbsp; <span></span> */}
                            </li>
                        )
                    })}
                </ul>
            </div>:""}
        </div>
        </>
    );
}

export default CountryPicker;