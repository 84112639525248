export const validateName = (text, name) => {
  if(text === null || text === ''){
    if(name === 'fname'){
      return {
        error: true,
        message: "Please enter your first name.",
      };
    } else if(name === 'lname'){
      return {
        error: true,
        message: "Please enter your last name.",
      };
    }
  }
  return {
    error: false,
    message: "",
  };
};

export const validateEmail = (text) => {
  if (
    !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      text
    )
  ) {
    return {
      error: true,
      message: "Please enter a valid email address.",
    };
  }
  return {
    error: false,
    message: "",
  };
};
export const validatePhone = (
  code,
  number,
  msg = "Mobile number cannot be less than 10 digits.",
  msgGrt = "Mobile number cannot be greater than 10 digits.",
  msgNull = "Please enter a mobile number."
) => {
  if(!code || !number) {
    return {
      error: true,
      message: msgNull
    }
  }else if(code === '91'){
    if(number.length < 10){
      return {
        error: true,
        message: msg
      }
    }else if(number.length > 10){
      return {
        error: true,
        message: msgGrt
      }
    }
    
  }

  return {
    error: false,
    message: "",
  };
};
