import { isEmpty } from '../utilities/utils';

const moengage = window?.Moengage;

export const urlsParam = (key) => {
  const queryParams = new URLSearchParams(window.location.search);
  let parsedParams = queryParams.get(key);
  if (parsedParams) {
    return parsedParams;
  }
  return null;
};

export const getAllParams = () => {
  return decodeURI(window.location.search)
    .replace('?', '')
    .split('&')
    .map((p) => p.split('='))
    .reduce((values, [key, value]) => {
      values[key] = value;
      return values;
    }, {});
};

export const trackEvent = (eventName) => {
  const trackedEventObj = {};
  for (const [key, value] of Object.entries(eventName)) {
    if (value) {
      Object.assign(trackedEventObj, { [key]: value });
    }
  }

  if (eventName) {
    moengage.track_event(eventName?.event, { ...trackedEventObj });
  }
};

export const trackEventWithUserDetails = (...keys) => {
  if (!moengage) {
    return;
  }
  for (const [key, value] of Object.entries(keys[0])) {
    if (key === 'event' && !isEmpty(value)) {
      moengage.track_event(value, {});
    }
    if (key === 'firstName' && !isEmpty(value)) {
      moengage.add_first_name(value);
    }
    if (key === 'lastName' && !isEmpty(value)) {
      moengage.add_last_name(value);
    }
    if (key === 'email' && !isEmpty(value)) {
      moengage.add_email(value);
    }
    if (key === 'mobile' && !isEmpty(value)) {
      moengage.add_mobile(value);
    }
    if (key === "id" && !isEmpty(value)) {
      moengage.add_unique_user_id(value);
    }
    if (key === 'params') {
      for (const [k, v] of Object.entries(value)) {
        if (k === 'utm_source' && !isEmpty(v)) {
          moengage.add_user_attribute('source', v);
        } else {
          moengage.add_user_attribute(k, v);
        }
      }
    }
  }
};

export const moEngageLogin = (userId) => {
  if (!moengage) {
    return;
  }
  moengage.add_unique_user_id(userId);
};

export const moEngageLogout = (userId) => {
  if (!moengage) {
    return;
  }
  moengage.destroy_session();
};
