import React, { useEffect, useState } from 'react';

import './styles.scss';
import logo from '../../assets/images/logo-2.svg';
import hbdLogo from "../../assets/images/hbdLogo.svg";
import arrow from '../../assets/images/arrowDown.svg';
import Navcross from '../../assets/images/navcross.svg';
import AvatarBadge from '../../assets/images/avatarBadge.svg';
import Placeholder from '../../assets/images/NoUser.svg';
import { Dropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { getProfileDetails } from '../../services/profileService';
import { getCookie } from '../../services';

import MobDrop from '../DropDownMobile/index';
import DoctorHeader from './DoctorHeader';
import { trackEvent } from '../../services/moengageServices';

function Header(props) {
  const history = useHistory();
  const [loggedIn, setLoggedIn] = useState(false);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [emailVerified, setEmailVerified] = useState(null);
  const [premiumMemeber, setPremiumMemeber] = useState(null);
  // const [isDashboardVisible, setDashboardVisible] = useState(null);
  const [roleId, setRoleId] = useState(4);

  const [threadoToken, setThreadoToken] = useState(null);
  const [initials, setInitials] = useState(null);
  const logout = () => {
    localStorage.removeItem('auth');
    // document.cookie = `authToken=;`
    const cookies = document.cookie.split('; ');
    for (let c = 0; c < cookies.length; c++) {
      const d = window.location.hostname.split('.');
      while (d.length > 0) {
        const cookieBase =
          encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
          '=; expires=' +
          new Date().toUTCString() +
          '; domain=' +
          d.join('.') +
          ' ;path=';
        const p = location.pathname.split('/');
        document.cookie = cookieBase + '/';
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/');
          p.pop();
        }
        d.shift();
      }
    }
    setLoggedIn(false);
    setOpen(false);
    if (process.env.REACT_APP_ENV === 'local') {
      history.push(process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL);
    } else {
      history.push('/login');
      // window.open(
      //   process.env.REACT_APP_CROSS_COOKIE_DOMAIN +
      //   "/login",
      //   "_self"
      // );
    }
  };
  useEffect(() => {
    // const authToken = localStorage.getItem('auth');
    const authToken = getCookie('authToken');
    if (authToken && !props.profileData) {
      setLoggedIn(true);
      getProfileApi();
    } else if (authToken && props.profileData && props.profileData.id) {
      setRoleId(props.profileData.roleId);
      setLoggedIn(true);
      setImage(props.profileData?.image?.url);
      // setImageIdentifier(props.profileData?.image?.id);
      // setThreadoToken(props.)
      setEmailVerified(props.profileData?.isEmailVerified);
      setPremiumMemeber(props.profileData?.isPremiumMember);
      // if (props.profileData?.roleId == 4) {
      //   setDashboardVisible(props.profileData?.company.isDashboardVisible);
      // }
      setFirstName(props.profileData?.firstName);
      setThreadoToken(props.profileData?.threadoSsoToken);
      // setSelectedAOC(tempArr);
      // if (props && props.onDashboard) {
      //   props.setProfileData(props.profileData);
      // }

      if (props.profileData?.firstName && props.profileData?.lastName) {
        const initialsStr =
          props.profileData?.firstName.charAt(0).toUpperCase() +
          props.profileData?.lastName.charAt(0).toUpperCase();
        setInitials(initialsStr);
        // userInitials(props.profileData?.firstName, props.profileData?.lastName);
      }
    }
  }, [props.profileData]);

  useEffect(() => {
    setImage(props.headerImage);
    setFirstName(props.firstName);
  }, [props.headerImage, props.firstName]);

  const location = useLocation();

  const homeClass = location.pathname === '/' ? 'active' : '';
  const diagnosticClass = location.pathname === '/diagnostic' ? 'active' : '';
  const teleconsultationsClass =
    location.pathname === '/experts' ? 'active' : '';
  const clinicClass = location.pathname === '/clinic' ? 'active' : '';
  const pcosClass = location.pathname === '/pcos' ? 'active' : '';

  const resoucesClass =
    location.pathname.match(/^\/blogs/) ||
    location.pathname.match(/^\/webinars/)
      ? 'active'
      : '';

  const programsClass =
    location.pathname.match(/^\/pcos/) ||
    location.pathname.match(/^\/vaginismus/)
      ? 'active'
      : '';

  // const dashboardClass = location.pathname.match(/^\/dashboard/)
  //   ? "active"
  //   : "";

  const getProfileApi = () => {
    getProfileDetails()
      .then((res) => {
        const data = res.data;
        if (data) {
          setImage(data?.image?.url);
          // setImageIdentifier(data?.image?.id);
          setEmailVerified(data?.isEmailVerified);
          setPremiumMemeber(data?.isPremiumMember);
          // if (data?.roleId == 4) {
          //   setDashboardVisible(data?.company.isDashboardVisible);
          // }
          setFirstName(data?.firstName);
          setThreadoToken(data?.threadoSsoToken);
          // setSelectedAOC(tempArr);
          // if (props && props.onDashboard) {
          //   props.setProfileData(data);
          // }
          if (data?.firstName && data?.lastName) {
            const initialsStr =
              data?.firstName.charAt(0).toUpperCase() +
              data?.lastName.charAt(0).toUpperCase();
            setInitials(initialsStr);
          }
        }
      })
      .catch((err) => {});
  };
  const redirectToWebsiteHome = () => {
    window.location.assign(
      `${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}`,
    );
  };

  return (
    <>
      {roleId == 2 ? (
        <DoctorHeader />
      ) : (
        <>
          <div className="spacer">&nbsp;</div>
          <div className="headerNew">
            <div className="headerNew-logo">
              <span
                onClick={() => {
                  window.open(
                    process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL,
                    '_self',
                  );
                }}
                style={{
                  cursor: 'pointer',
                }}
                className="logoImg"
              >
                <img src={hbdLogo} alt="LOGO" />
              </span>
            </div>

            <div className={`headerNew-navLink`}>
              <ul>
                <li className="desktop-link home-link">
                  <p
                    className={'homeClass'}
                    onClick={() => redirectToWebsiteHome()}
                  >
                    HOME
                  </p>
                </li>

                <li className="desktop-link">
                  <a
                    href={`${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}/experts`}
                  >
                    <p className={clinicClass} style={{ whiteSpace: 'nowrap' }}>
                      CONSULT DOCTORS
                    </p>
                  </a>
                </li>

                <li className="desktop-link home-link">
                  <a
                    href={`${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}/diagnostic`}
                  >
                    <p className={diagnosticClass}>DIAGNOSTICS</p>
                  </a>
                </li>

                <li>
                  {dropdown(
                    'PROGRAMS',
                    [
                      {
                        name: 'PCOS',
                        link: `${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}/pcos`,
                      },
                      {
                        name: 'VAGINISMUS',
                        link: `${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}/vaginismus`,
                      },
                    ],
                    '',
                    false,
                    ['pcos', 'vaginismus'].includes(props.page) ? 'active' : '',
                  )}
                </li>

                <li>
                  {dropdown(
                    'LIBRARY',
                    [
                      {
                        name: 'BLOGS',
                        link: `${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}/blogs`,
                      },
                      {
                        name: 'WEBINARS',
                        link: `${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}/webinars`,
                      },
                    ],
                    '',
                    true,
                  )}
                </li>

                {/* {loggedIn && (premiumMemeber === 1 || isDashboardVisible) && (
                  <>
                    <li>
                      <Link to="/dashboard">
                        <p className={`desktop-link ${dashboardClass}`}>
                          DASHBOARD
                        </p>
                      </Link>
                    </li>
                  </>
                )} */}
                {loggedIn ? (
                  <li>
                    {User(false, logout, firstName, loggedIn, image, initials)}
                  </li>
                ) : (
                  <li>
                    <div className="auth-btn">
                      <button
                        className="login"
                        onClick={() => {
                          props.setError(null);
                            trackEvent({
                              event:"Button_Clicked_Custom",
                              source_page_name:"Home",
                              source_page_url:window.location.href,
                              next_page_name:"Home",
                              next_page_url:"/",
                              button_name:"SAVE",
                            })
                          history.push('/login');
                        }}
                      >
                        LOGIN
                      </button>
                      {/* <button
                        onClick={() => {
                          history.push('/sign-up');
                          props.setError(null);
                        }}
                      >
                        SIGN UP
                      </button> */}
                    </div>
                  </li>
                )}
              </ul>
            </div>

            <div
              className={`headerNew-navLinkMobile ${
                open ? null : 'navMobHide'
              }`}
            >
              <Link to="/" className="logoImg">
                <img src={hbdLogo} alt="LOGO" />
              </Link>
              <ul>
                {loggedIn && (
                  <li>
                    {User(true, logout, firstName, loggedIn, image, initials)}
                  </li>
                )}

                {/* {loggedIn && (premiumMemeber === 1 || isDashboardVisible) && (
                  <div className="extra">
                    <li className="extra-first">
                      <Link to="/dashboard">
                        <p
                          className={dashboardClass}
                          onClick={() => setOpen(false)}
                        >
                          DASHBOARD
                        </p>
                      </Link>
                    </li>
                  </div>
                )} */}

                <li>
                  <Link to="/">
                    <p
                      className={homeClass}
                      onClick={() => redirectToWebsiteHome()}
                    >
                      HOME
                    </p>
                  </Link>
                </li>

                <li className="pointer">
                  <a
                    href={`${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}/appointments`}
                  >
                    <p>APPOINTMENTS</p>
                  </a>
                </li>

                <li>
                  <a
                    href={`${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}/experts`}
                  >
                    <p className={clinicClass} style={{ whiteSpace: 'nowrap' }}>
                      CONSULT DOCTORS
                    </p>
                  </a>
                </li>

                <li>
                  <a
                    href={`${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}/diagnostic`}
                  >
                    <p
                      className={diagnosticClass}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      DIAGNOSTICS
                    </p>
                  </a>
                </li>

                <li>
                  <MobDrop
                    name={'PROGRAMS'}
                    links={[
                      {
                        name: 'PCOS',
                        link: `${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}/pcos`,
                        external: false,
                      },
                      {
                        name: 'VAGINISMUS',
                        link: `${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}/vaginismus`,
                        external: false,
                      },
                    ]}
                    class={programsClass}
                    setOpen={setOpen}
                  />
                </li>

                <li>
                  <MobDrop
                    name={'LIBRARY'}
                    links={[
                      {
                        name: 'BLOGS',
                        link: `${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}/blogs`,
                        external: true,
                      },
                      {
                        name: 'WEBINARS',
                        link: `${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}/webinars`,
                        external: true,
                      },
                    ]}
                    class={resoucesClass}
                  />
                </li>

                {!loggedIn && (
                  <li>
                    <div className="auth-btn">
                      <button
                        className="login"
                        onClick={() => {
                          setOpen(false);
                          history.push('/login');
                        }}
                      >
                        LOGIN
                      </button>
                      <button
                        onClick={() => {
                          setOpen(false);
                          history.push('/sign-up');
                        }}
                      >
                        SIGN UP
                      </button>
                    </div>
                  </li>
                )}

                {loggedIn && (
                  <>
                    {/* <li>
                      <p
                        onClick={() => {
                          setOpen(false);
                          history.push("/profile");
                        }}
                      >
                        PROFILE
                      </p>
                    </li> */}

                    <li onClick={() => logout()}>
                      <p onClick={(e) => e.click}>LOGOUT</p>
                    </li>
                  </>
                )}
              </ul>
            </div>

            {open ? (
              <img
                className="hamburger"
                style={{ maxHeight: '1rem', transform: 'translate(-1rem, 0)' }}
                src={Navcross}
                alt="close"
                onClick={() => setOpen(false)}
              ></img>
            ) : (
              <label
                className="nav-open hamburger"
                onClick={() => setOpen(true)}
              >
                <i></i>
                <i></i>
                <i></i>
              </label>
            )}
          </div>
        </>
      )}
    </>
  );
}
export default Header;

function dropdown(title, sublinks, c, linkToOtherWebsite) {
  return (
    <Dropdown>
      <Dropdown.Toggle>
        <div className="desktop-link link-name">
          <p className={c}> {title}</p>

          {!title ? <img src={arrow} className="img-arrow" alt="" /> : void 0}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {sublinks.map((e, index) => {
          return (
            <div key={index}>
              {linkToOtherWebsite ? (
                <Dropdown.Item>
                  <p onClick={() => window.open(e.link, '_self')}>{e.name}</p>
                </Dropdown.Item>
              ) : (
                <Dropdown.Item onClick={() => window.open(e.link, '_self')}>
                  <p onClick={e.click}>{e.name}</p>
                </Dropdown.Item>
              )}
              {index + 1 === sublinks.length ? null : (
                <hr className="mx-4 my-2" />
              )}
            </div>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function User(mob, logout, firstName, loggedInFLag, img, initials) {
  return (
    <div className="user">
      {mob ? null : (
        <span className="sep">
          <p>|</p>
        </span>
      )}

      {mob ? (
        <div
          className="d-flex pointer align-items-center"
          onClick={() => {
            window.open('profile', '_self');
          }}
        >
          <div className="avatar">
            {img ? (
              <img src={img} alt="userImage"></img>
            ) : initials ? (
              <div className="initials">{initials}</div>
            ) : (
              <img src={Placeholder} alt="userImage"></img>
            )}
            <img src={AvatarBadge} alt="badge" className="avatar-badge"></img>
          </div>

          <p>{loggedInFLag ? firstName : ''}</p>
        </div>
      ) : (
        <div
          className="d-flex align-items-center pointer"
          onClick={() => {
            window.open('profile', '_self');
          }}
        >
          <p>{loggedInFLag ? firstName : ''}</p>
          <div className="avatar">
            {img ? (
              <img src={img} alt="userImage"></img>
            ) : initials ? (
              <div className="initials">{initials}</div>
            ) : (
              <img src={Placeholder} alt="userImage"></img>
            )}
            <img src={AvatarBadge} alt="badge" className="avatar-badge"></img>
          </div>
        </div>
      )}
      {mob
        ? null
        : dropdown('', [
            // {
            //   name: "PROFILE",
            //   link: "/profile",
            // },
            {
              name: 'APPOINTMENTS',
              link: `${process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL}/appointments`,
            },
            {
              name: 'LOGOUT',
              link: '',
              click: logout,
            },
          ])}
    </div>
  );
}
