import React, { useState, useEffect } from 'react';
import whiteArrow from '../../assets/images/whiteArrow.svg';
import arrow from '../../assets/images/arrowDown.svg';
import { Link } from 'react-router-dom';
import './styles.scss';
function MobDrop(props) {
  const [show, setshow] = useState(false);

  useEffect(() => {
    if (props.class) {
      setshow(true);
    }
  }, []);

  return (
    <>
      <p
        className={`${props.class} ${show ? 'mob-li-name ' : ''}`}
        onClick={() => setshow(!show)}
      >
        {props.name}

        {/* {show ? (
          <img src={whiteArrow} alt="" className="img-arrow" />
        ) : props.class ? (
          <img src={whiteArrow} className="img-arrow rev" alt="" />
        ) : (
          <img src={arrow} className="img-arrow" alt="" />
        )} */}
      </p>
      {show && (
        <div className='service-drop'>
          <ul>
            {props.links.map((e, index) => {
              return (
                <>
                  {e.external ? (
                    <p
                      onClick={() => window.open(e.link, '_self')}
                      className='linkp'
                    >
                      {e.name}
                    </p>
                  ) : (
                    <Link to={`/${e.link}`}>
                      <li
                        onClick={() => {
                          if (props.setOpen) props.setOpen(false);
                        }}
                      >
                        {e.name}
                      </li>
                    </Link>
                  )}
                  {index + 1 === props.links.length ? null : <hr />}
                </>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}
export default MobDrop;
