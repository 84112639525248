import { useEffect, useState } from 'react';
import './styles.scss';
import { Helmet } from 'react-helmet';
import commonString from '../../../common/stringLiterals';
import PrimaryButton from '../../PrimaryButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import cross from '../../../assets/images/cross.svg';
import modalClose from '../../../assets/images/modalClose.svg';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '../../../services/moengageServices';
import { trackGTMEvent } from '../../../services/gtmService';

function OtpInputModal(props) {
  const { isOtpModal, setOtpModal } = props;
  const history = useHistory();

  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [min, setMin] = useState('');
  const [sec, setSec] = useState('');
  const [toggleResend, setToggleResend] = useState(false);
  const [time, setTime] = useState(60);
  // const [otpError,setOtpError] = useState({});

  const handleOtpChange = (element, index, e) => {
    if (e.nativeEvent.inputType === 'deleteContentBackward') {
      setOtp([...otp.map((d, idx) => (idx === index ? '' : d))]);
      if (element.previousSibling) {
        element.previousSibling.focus();
        setTimeout(() => {
          element.previousSibling.setSelectionRange(0, 1);
        }, 10);
      }
    } else if ('insertText') {
      if (isNaN(element.value)) return false;
      setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    }
    // if (isNaN(element.value)) return false;
    // setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    // if (element.nextSibling) {
    //   element.nextSibling.focus();
    // }
  };

  const [enableBtn, setEnableBtn] = useState(false);

  const [otpVal, setOtpVal] = useState(null);

  const handleEnableBtn = () => {
    let flag = 1;
    otp.map((x) => {
      if (x === '') {
        flag = 0;
      }
    });

    if (flag === 1) {
      setEnableBtn(true);
      return true;
    } else {
      setEnableBtn(false);
      // setOtpVal(null);
      return false;
    }
  };

  useEffect(() => {
    // inputRef.current.focus();
    document.getElementById('singleInput0').focus();
  }, []);

  function keyPress(e) {
    if (e.keyCode === 13 && enableBtn) {
      otpVerification();
    }
  }

  const otpVerification = () => {
    trackEvent({
      event:"Button_Clicked_Custom",
      source_page_name:"Login",
      source_page_url:window.location.href,
      next_page_name:"Home",
      next_page_url:"/",
      button_name:"Verify_OTP",
    })
    //verifyOtp-gtm
    trackGTMEvent({
      event:"Verify_OTP",
      refering_url: "/",
      current_url: window.location.href,
    });
    var ans = handleEnableBtn();

    if (ans) {
      //make join code
      let otpStr = '';
      for (let i = 0; i < otp.length; i++) {
        otpStr += otp[i];
      }

      props.verifyOtp(otpStr);
      //make api call
      // var success = false; //make this according to response

      // if (otpStr === "654321") {
      //   success = true;
      //   history.push("/profile");
      // }

      // if (success) {
      //   setOtpVal(true);
      // } else {
      //   setOtpVal(false);
      // }
    }
  };

  const sendOtpAgain = () => {
    setOtp(Array(6).fill(''));
    setToggleResend(false);
    props.setOtpErrorResponse(null);
    setTime(60);
    props.setResendText(null);
    props.resendOtp();
  };

  const handleCloseModal = () => {
    setOtpModal(!isOtpModal);
  };

  useEffect(() => {
    handleEnableBtn();
  }, [otp]);

  useEffect(() => {
    timer();
  }, [time]);

  const timer = () => {
    let valMin = Math.floor(time / 60);
    setMin(valMin);
    let valSec = time % 60;
    if (valSec < 10) {
      let paddedValue = '0' + valSec;
      setSec(paddedValue);
    } else {
      setSec(valSec);
    }

    if (time > 0) {
      setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    } else {
      setToggleResend(true);
    }
  };
  const [isOtpVerified, setOtpVerified] = useState(null);
  return (
    <>
      {/* <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          className='test'
        >
          <Modal.Body className='px-5 py-4'>
               
          </Modal.Body>
        </Modal> */}
      <Helmet title={`Verify Mobile OTP${commonString.title}`} />
      <div className='modalPhone'>
        <div className='modalPhone-content1' />
        <div className='modalPhone-content2'>
          <div className='modalPhone-content2-set'>
            <Col className='modalPhone-content2-set d-flex flex-column align-items-center justify-content-around'>
              <img
                src={modalClose}
                className='modal-close'
                onClick={handleCloseModal}
              />
              <Row className='text-center justify-content-center font-weight-bold modal-content-title '>
                <div>
                  We have sent a one-time <br />
                  password to your device.
                </div>
              </Row>
              <Row className='text-center justify-content-center modal-content-subtitle '>
                Please enter the 6-digit password you received below to confirm:
              </Row>
              <Row className='justify-content-start m-0'>
                {/* <div className="d-flex flex-row justify-content-center align-items-center modal-content-otp"> */}
                {/* {otpVal === null ? null : otpVal ? (
                  <small className="ml-3 verified font-weight-bold">
                    <img src={otpVerified} alt="verified" />
                    OTP has been verified.
                  </small>
                ) : ( */}
                {props.otpErrorResponse && !props.otpErrorResponse.success ? (
                  <small className='notVerified font-weight-bold'>
                    <img src={cross} alt='wrong otp' />
                    {props.otpErrorResponse && props.otpErrorResponse.message}
                  </small>
                ) : props.resendText ? (
                  <small className='ext-success font-weight-bold'>
                    {props.resendText}
                  </small>
                ) : (
                  ''
                )}

                <div className='modal-content-otp  justify-content-center'>
                  {otp.map((data, index) => {
                    return (
                      <input
                        type='tel'
                        maxLength={1}
                        className={`modal-content-otp-input  ${
                          props.otpErrorResponse &&
                          !props.otpErrorResponse.success
                            ? 'red-border-input'
                            : props.otpErrorResponse &&
                              props.otpErrorResponse.success
                            ? 'green-border-input'
                            : ''
                        }`}
                        value={data}
                        id={`singleInput${index}`}
                        key={index}
                        onInput={(e) => handleOtpChange(e.target, index, e)}
                        onKeyDown={(e) => keyPress(e)}
                        // onChange={(e) => handleOtpChange(e.target, index,e)}
                      />
                    );
                  })}
                </div>
              </Row>

              <Row className='send-otp-button'>
                <PrimaryButton
                  id="btn-verify-otp"
                  value='Verify OTP'
                  handleClick={otpVerification}
                  checkedValue={enableBtn}
                />
              </Row>
              <Row className='justify-content-center '>
                {toggleResend ? (
                  <div className='modal-content-resendLink'>
                    Didn't receive an OTP?{' '}
                    <span
                      onClick={() => {
                        if (time === 0) sendOtpAgain();
                      }}
                      className={
                        toggleResend
                          ? 'font-weight-bold text-dark modal-content-resendLink-underline'
                          : 'font-weight-bold text-muted modal-content-resendLink-underline'
                      }
                    >
                      Click here to resend.
                    </span>
                  </div>
                ) : (
                  <div className='modal-content-resendLink'>
                    <span className='font-weight-bold text-dark'>
                      {'Resend OTP in ' + '0' + min + ':' + sec + ' '}
                    </span>
                  </div>
                )}
                {/* <div className="modal-content-resendLink">
                  <span className='font-weight-bold text-dark'>{'0'+ min+':'+sec+' '}</span>
                  Didn’t receive a password?
                    {" "}
                  <span
                    onClick={()=> {if(time===0)sendOtpAgain()}}
                    className={toggleResend ?'font-weight-bold text-dark modal-content-resendLink-underline': 'font-weight-bold text-muted modal-content-resendLink-underline'}
                  >
                    Click here to resend.
                  </span>
                </div> */}
              </Row>
            </Col>
          </div>
        </div>
      </div>
    </>
  );
}

export default OtpInputModal;
