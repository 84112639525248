import React from 'react';
import whatsapp from '../../assets/images/SocialMedia/whatsappFooter.svg';
import './style.scss';

const Whatsapp = () => {
  const iconClicked = () => {
    const mobile = document.querySelector('.mobile');
    const desktop = document.querySelector('.desktop');

    mobile.style.display = 'none';
    desktop.style.display = 'block';

    setTimeout(() => {
      desktop.style.display = 'none';
      mobile.style.display = 'block';
    }, 3000);
  };

  return (
    <>
      <a
        href='https://api.whatsapp.com/send/?phone=919882080808&text=Hello&app_absent=0'
        target='_blank'
        className='desktop'
      >
        <div className='whatsapp'>
          <p className='text'>Message Us</p>

          <img src={whatsapp} className='img' />
        </div>
      </a>

      <a
        href='https://api.whatsapp.com/send/?phone=919882080808&text=Hello&app_absent=0'
        target='_blank'
      >
        <div
          className='mobile'
          // onClick={iconClicked}
        >
          <div className='whatsapp'>
            <img src={whatsapp} className='img' />
          </div>
        </div>
      </a>
    </>
  );
};

export default Whatsapp;
