import { useEffect, useState } from 'react';
import './styles.scss';
import leftQuote from '../../assets/images/leftQuote.svg';
import Card from 'react-bootstrap/Card';

const LandingCard = () =>{
    return (
        <Card className='px-3 card-width' >
            <Card.Body className='d-flex flex-column justify-content-start align-items-start'>
                <Card.Title className='text-left'>
                    <img src={leftQuote} alt="Proactive Banner" className='landing-content-wrapper-texts-quotes'/>
                </Card.Title>
                <Card.Text className='text-left landing-content-wrapper-texts-body font-weight-normal'>
                    Taking care of yourself doesn't mean me first, it means me too.
                </Card.Text>
                <Card.Title className='landing-content-wrapper-texts-title text-left font-weight-bold'>- L.R. Knost, Author {'&'} Social Activist</Card.Title>
            </Card.Body>
        </Card>
    );
}

export default LandingCard;