import { axiosInstance } from './index';
import axios from 'axios';

import BackendConstants from '../common/backendConstants';

export const uploadImageService = async (file) => {
  const { name, type } = file;
  const data = {
    fileName: name.split('.')[0],
    mimeType: type,
    objType: 'images',
    sourceDisk: 'media',
  };
  var formData = new FormData();
  formData.append('file', file);

  return axiosInstance
    .post(BackendConstants.uploads, data)
    .then((imageUploadResponse) => {
      
      if (imageUploadResponse.code === 200) {
        return axios
          .put(imageUploadResponse.data.url, file, {
            headers: {
              'Content-Type': type,
            },
          })
          .then((s3Response) => {
            if (s3Response.status === 200) {
              return imageUploadResponse;
            }
          })
          .catch((err) => {
            throw err;
          });
      }
    })
    .catch((err) => {
      throw err;
    });
};
