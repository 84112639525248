import "./index.scss";
import GreenTickImg from "../../assets/images/Profile/greenTick.svg";
import { useState, useEffect } from "react";
function InputField({ data, isLoginPage = false }) {
  const [move, setMove] = useState(0);

  const mobileVerfied = true;
  // const emailVerfied = true;

  const lostFocus = (e) => {
    let x= ""
    if(data && data.inputName){
      x = data.valfn(e.target.value,data.inputName);
    }else{
      x = data.valfn(e.target.value);
    }
    
    if (x.error) {
      setError(x.message);
    } else {
      setError("");
    }
    setMove(move + 1);
  };

  const [error, setError] = useState("");

  // const validate = (valfn) => {
  //   let x = valfn(data.value);
  //   if (x.error) {
  //     setError(x.message);
  //   } else {
  //     setError("");
  //   }
  // };

  const onChange = (e) => {
    data.onChange(e);
    // let x = data.valfn(e.target.value);
    // if (x.error) {
    //   setError(x.message);
    // } else {
    //   setError("");
    // }
  };

  useEffect(() => {
    data.error(error);
  }, [error]);
  return (
    <>
    <div className="inputfield">
      {/* <p> */}
      <label>
        {data.label} <span>*</span>
      </label>
      <input
        type={data.type}
        placeholder={data.placeholder}
        onChange={(e)=>data.onChange(e)}
        value={data.value}
        name={data.name}
        onBlur={lostFocus}
        autoComplete="off"
        className={`${isLoginPage?  error ? "input-error input-loginpage" : "input-loginpage" : error ? "input-error" : ""}`}
        disabled= {data.disabled}
      ></input>
      {data.emailVerfied ? (
        data.name === "email" ? (
          <img src={GreenTickImg} alt="~" className="inputfield-tick"></img>
        ) : null
      ) : null}
      {/* </p> */}
      
      {mobileVerfied ? (
        data.name === "phone" ? (
          <img src={GreenTickImg} alt="~" className="inputfield-tick"></img>
        ) : null
      ) : null}
      {error ? <small className="inputfield-error">{error}</small> : null}
      {data.apiResponseErrorEmail ? <small className="inputfield-error">{data.apiResponseErrorEmail}</small> : null}

      {/* {data.permission ? (
        <div className="bottom-subText">
          <span>
            <input type="checkbox"></input>
          </span>
          <p>
            I opt in to receiving updates on whatsapp and on phone from
            Proactive for her(Millennial Healthtech Private Limited)
          </p>
        </div>
      ) : null} */}
    </div>
    {error ? <small className="inputfield-error-mobile">{error}</small> : null}
    </>
  );
}

export default InputField;