import './styles.scss';

const PrimaryButton = (props) => {
  const buttonClick = () => {
    if (props.link)
      window.open(
        process.env.REACT_APP_WEBSITE_PAGE_REDIRECT_BASEURL + props.link,
        '_self'
      );
    props && props.handleClick && props.handleClick();
  };
  return (
    <button
      id={props.id}
      type={props.type}
      name={props.name}
      onClick={() => buttonClick()}
      className={
        props.checkedValue ||
        props.name === 'Login' ||
        props.name === 'appointmentCompleted'
          ? `button-primary`
          : `button-disabled`
      }
      disabled={!props.checkedValue && props.name === 'Signup'}
      style={props.style}
    >
      {props.value}
    </button>
  );
};

export default PrimaryButton;
